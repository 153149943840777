import { BarterLanding, DotsPattern } from '../../../../assets'
import InView from 'react-intersection-observer'
import { useState } from 'react'

const BarterSection = () => {
  const [show, setshow] = useState(false)
  const basePosition = 'translate-y-0 opacity-100'
  const picAnimation = 'translate-y-5 opacity-0'
  return (
    <InView
      onChange={(inView, entry) => {
        setshow(inView)
        // console.log('TES', inView, entry)
      }}
    >
      <div
        className={`flex flex-row fhd:mx-89px mx-4 md:px-0 px-4 md:h-card_asset_1366 h-48 md:py-0 py-8 md:pl-20 mb-40 rounded-xl shadow-cardLanding border lg:w-screen_while_big w-80 border-gray-50 relative transform ease-in-out duration-700 ${
          show ? basePosition : picAnimation
        }`}
      >
        <div className="flex flex-col justify-center h-full">
          <p className="md:text-4xl text-lg md:mb-4 mb-2 font-poppins font-bold text-left 2xl:leading-normal md:leading-normal tracking-wide text-teks">
            <span className="text-hijau_hutan">Tukar </span> asetmu dengan aset
            yang kamu suka
          </p>
          <p className="md:text-lg text-xs font-poppins font-normal text-left md:leading-normal md:mb-12 text-teks_2">
            Bosan dengan asetmu? Tukar aset dengan aset creator lain dengan
            fitur Barter
          </p>
        </div>
        <div className="md:order-2 order-1 my-8 md:px-0 px-2 w-full flex justify-center items-center relative overflow-visible">
          <img
            src={BarterLanding}
            className="object-cover h-auto w-96 rounded-lg z-10"
            alt="human"
          />
        </div>
        <img
          src={DotsPattern}
          className="object-cover md:w-48 w-24 h-auto rounded-lg absolute top-0 right-0"
          alt="human"
        />
      </div>
    </InView>
  )
}

export default BarterSection
