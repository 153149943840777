import { useState } from 'react'
import InView from 'react-intersection-observer'

const TitleSection = ({ title, info, additionalStyle, isCenter = true }) => {
  const [show, setshow] = useState(false)
  const basePosition = 'translate-y-0 opacity-100'
  const animatedPosition = 'translate-y-5 opacity-0'
  return (
    <InView
      onChange={(inView, entry) => {
        setshow(inView)
      }}
    >
      <div
        className={`flex flex-col justify-center items-center transform ease-in-out duration-700 ${
          show ? basePosition : animatedPosition
        }`}
      >
        <p
          className={`md:text-4xl text-xl mb-4 font-poppins font-bold ${
            isCenter ? 'text-center' : 'text-left'
          } 2xl:leading-normal md:leading-normal tracking-wide text-teks ${
            additionalStyle && additionalStyle
          }`}
        >
          {title}
        </p>
        {info && (
          <p className="md:text-lg text-sm md:mb-4 mb-0 font-poppins font-normal text-center 2xl:leading-normal md:leading-normal tracking-normal text-teks_2">
            {info}
          </p>
        )}
      </div>
    </InView>
  )
}

export default TitleSection
