import { ProjectImg, UtilImg } from '../../../../assets'
import InView from 'react-intersection-observer'
import { useState } from 'react'

const dataUtil = [
  {
    title: 'Dapatkan Utilitas saat pembelian NFT',
    info: 'Dapatkan utilitas berupa tiket, kode unik dan membership saat pembelian NFT',
    img: UtilImg,
  },
  {
    title: 'Buat Projek Utitilas NFT',
    info: 'Kamu bisa membuat projek utilitas berupa tiket, kode unik, serta membership yang bisa kamu bundling bersama NFT yang akan kamu jual',
    img: ProjectImg,
  },
]

const CardUtil = ({ img, title, info }) => {
  return (
    <div className="flex flex-col items-center justify-start">
      <img src={img} alt="" className="h-auto md:w-80 w-48 mb-4" />
      <p className="md:text-2xl text-base font-poppins font-semibold text-center md:leading-normal mb-2 text-teks">
        {title}
      </p>
      <p className="md:text-lg text-xs font-poppins font-normal text-center md:leading-normal text-teks_2">
        {info}
      </p>
    </div>
  )
}

const UtilitySection = ({ handleToUtility }) => {
  const [show, setshow] = useState(false)
  const basePosition = 'translate-y-0 opacity-100'
  const picAnimation = 'translate-y-5 opacity-0'
  return (
    <InView
      onChange={(inView, entry) => {
        setshow(inView)
        // console.log('TES', inView, entry)
      }}
    >
      <div
        className={`flex flex-col justify-center items-center fhd:mx-89px mx-4 md:px-0 px-4 md:py-20 py-16 md:mb-20 mb-0 lg:w-screen_while_big w-80 relative transform ease-in-out duration-700 ${
          show ? basePosition : picAnimation
        }`}
      >
        <div className="flex flex-col justify-center h-full">
          <p className="md:text-4xl text-xl font-poppins font-bold text-center 2xl:leading-normal md:leading-normal tracking-wide text-teks">
            Jelajahi
            <span className="text-hijau_hutan"> Utilitas</span> di NFT Saya
          </p>

          <div className="grid md:grid-cols-2 grid-cols-1 gap-x-24 gap-y-8">
            {dataUtil.map(({ title, img, info }, idx) => (
              <CardUtil key={idx} title={title} img={img} info={info} />
            ))}
          </div>
        </div>
      </div>
    </InView>
  )
}

export default UtilitySection
