import { useContext } from "react"
import socketContext from "../context/Socket/socketContext"
import { BLOCKCHAIN_BINANCE, BLOCKCHAIN_ETHERIUM, BLOCKCHAIN_POLYGON } from "../utils/constants/blockChainTypes";

const useGetPrice = () => {
    const SocketContext = useContext(socketContext)
    const {blockchain_price} = SocketContext
    const getBlockchainPrice = (blockchainType) => {
        switch (blockchainType) {
            case BLOCKCHAIN_BINANCE:
                return blockchain_price.binance_price
            case BLOCKCHAIN_ETHERIUM:
                return blockchain_price.ether_price
            case BLOCKCHAIN_POLYGON:
                return blockchain_price.matic_price
            default:
                return blockchain_price.matic_price
        }
    }
    return {
        getBlockchainPrice
    }
}

export default useGetPrice