import { useCallback } from 'react'
import NumberFormat from 'react-number-format'
import { useNavigate } from 'react-router-dom'
import VerifiedLogo from '../../assets/logo/verified_logo'
import useGetPrice from '../../hooks/useGetPrice'
import { SMALL_THUMBNAIL_IMAGE_TYPE } from '../../utils/constants/renderImgTypes'
import BlockchainIcon from '../Badges/BlockchainIcon'
import Card from './Card'

const CardBestOffer = ({ data, ...props }) => {
  const { id, thumbnailUrl, name, collectionByCollectionId, blockchainType } =
    data
  const { getBlockchainPrice } = useGetPrice()
  const finalPrice = getBlockchainPrice(blockchainType)
  const navigate = useNavigate()
  const handleToDetail = useCallback(() => {
    navigate(`/asset/${id}`)
  }, [id, navigate])
  return (
    <Card
      {...props}
      onClick={handleToDetail}
      className="flex flex-col items-center cursor-pointer rounded-xl shadow-md transform transition ease-in-out delay-150 hover:-translate-y-4 hover:shadow-lg"
      width={'380px'}
    >
      <div className="bg-black h-96 w-full items-center justify-center flex rounded-t-xl">
        <img
          alt="collection"
          src={thumbnailUrl + SMALL_THUMBNAIL_IMAGE_TYPE}
          className="object-cover h-80 rounded-t-xl"
        />
      </div>
      <div className="px-6 flex flex-col py-4 items-start w-full bg-white rounded-b-xl">
        <div className="flex flex-row items-center mb-1">
          <p className="font-poppins font-medium text-base text-teks_2 mr-2">
            {collectionByCollectionId?.name ?? 'Belum masuk koleksi'}
          </p>
          {collectionByCollectionId?.isVerified && <VerifiedLogo />}
        </div>
        <p className="font-poppins font-semibold text-xl text-teks mb-2 line-clamp-1">
          {name}
        </p>
        <NumberFormat
          value={Math.ceil(parseFloat(data?.publishedPrice) * finalPrice)}
          displayType={'text'}
          thousandSeparator={true}
          renderText={(value, props) => (
            <div className="inline-flex justify-between w-full mt-3">
              <div className="flex line-clamp-1">
                {data?.publishedPrice !== 0 ? (
                  <>
                    <div className={`flex flex-row space-x-2 items-center`}>
                      <BlockchainIcon blockchainType={blockchainType} />
                      <p className="lg:text-base font-medium font-poppins bg-space-nowrap text-teks_2 line-clamp-1">
                        {data?.publishedPrice}
                      </p>
                    </div>
                  </>
                ) : (
                  <div className="mb-2">
                    <p className="lg:text-base font-medium font-poppins bg-space-nowrap text-teks_2 line-clamp-1">
                      Pemilik Belum Pasang Harga
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        />
      </div>
    </Card>
  )
}

export default CardBestOffer
