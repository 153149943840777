import { useContext } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper'
import CardBestOffer from '../../../../component/Card/CardBestOffer'
import assetContext from '../../../../context/Asset/assetContext'
import { RANDOM_ASSETS_CACHE_TYPE } from '../../../../utils/constants/assetCacheTypes'
import ResponsiveContext from '../../../../context/Responsive/responsiveContext'

const BestOfferingSection = () => {
  const AssetContext = useContext(assetContext)
  const Responsive = useContext(ResponsiveContext)

  return (
    <div className="relative items-center  md:mb-12 mb-0 w-full py-12 2xl:w-screen_while_big">
      <div className="w-full justify-start items-start mx-4 md:mx-89px fhd:mx-0 md:mb-8 mb-4">
        <p className="font-poppins font-bold md:text-4xl text-2xl text-teks tracking-wide">
          Aset Trending
        </p>
      </div>
      <div className="px-4 md:px-89px fhd:px-0 flex items-center justify-center relative">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, Autoplay]}
          spaceBetween={24}
          slidesPerView={Responsive ? 1 : 3}
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          autoplay={{ delay: 3000 }}
          loop={true}
          style={{ paddingBottom: '20px' }}
        >
          <div className="mt-8" />
          {AssetContext[RANDOM_ASSETS_CACHE_TYPE] ? (
            AssetContext[RANDOM_ASSETS_CACHE_TYPE].map((data, i) => (
              <SwiperSlide key={i}>
                <CardBestOffer data={data} />
              </SwiperSlide>
            ))
          ) : (
            <div>No Data</div>
          )}
        </Swiper>
      </div>
    </div>
  )
}

export default BestOfferingSection
