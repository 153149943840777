import React, { useContext } from "react";
import Card from "./Card";
import ResponsiveContext from "../../context/Responsive/responsiveContext";
// import classNames from "classnames";

const CardTestimoni = ({ img, title, content,...props }) =>  {
    const responsive = useContext(ResponsiveContext)
    // const cardClass = classNames({"inline-flex items-start px-8 py-12 landing-page-card-background" : !responsive}, {"flex flex-col items-center px-8 py-4 shadow-lg rounded-lg snap-center": responsive})
    return (
    <Card {...props} className="md:bg-none bg-white hover:bg-white flex md:flex-row flex-col md:items-start items-center px-8 md:py-8 py-4 rounded-lg md:w-auto w-56 mr-8 md:mr-0 transform transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:shadow-cardLanding">
         <img src={img} alt="testimoni" className="md:mr-8 mr-0 md:mb-0 mb-4" style={{width: responsive ? "60px" : "auto"}} />
            <div className="flex flex-col w-full">
                <p className="font-poppins md:font-semibold font-medium md:text-xl text-base md:text-left text-center text-teks md:mb-3 mb-2">{title}</p>
                <p className="font-poppins font-normal md:text-base text-xs md:text-left text-center text-teks_2">{content}</p>
            </div>
    </Card>
)
}

export default CardTestimoni