import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper'
import { useCallback, useContext, useState } from 'react'
import InView from 'react-intersection-observer'
import { useNavigate } from 'react-router-dom'
import assetContext from '../../../../context/Asset/assetContext'
import filterContext from '../../../../context/Filter/filterContext'
import { CATEGORIES_CACHE_TYPE } from '../../../../utils/constants/assetCacheTypes'
import { SMALL_THUMBNAIL_IMAGE_TYPE } from '../../../../utils/constants/renderImgTypes'
import TitleSection from '../TitleSection'
import ResponsiveContext from '../../../../context/Responsive/responsiveContext'
import { UserImage } from '../../../../assets'

const CategoryInfo = ({
  thumbnailUrl,
  name,
  id,
  FilterContext,
  navigate,
  ...prop
}) => {
  const handleClick = useCallback(async () => {
    await FilterContext?.addCategory(id)
    await FilterContext?.addActiveFilter('category')
    navigate('/assets#category')
  }, [FilterContext, id, navigate])

  return (
    <div
      {...prop}
      onClick={handleClick}
      className={`flex flex-col cursor-pointer md:h-60 md:w-64 h-64 w-64 shadow-md rounded-2xl transform transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:shadow-cardLanding duration-300`}
    >
      <div className="h-48 overflow-hidden bg-black rounded-t-2xl">
        <img
          className="h-full w-full object-cover"
          src={
            thumbnailUrl ? thumbnailUrl + SMALL_THUMBNAIL_IMAGE_TYPE : UserImage
          }
          alt="thumbnail"
        />
      </div>
      <div className="flex py-4 px-6 bg-white rounded-b-2xl">
        <p className="text-base text-teks font-poppins font-semibold text-center">
          {name}
        </p>
      </div>
    </div>
  )
}

const CategoryMobile = ({ AssetContext, FilterContext, navigate }) => {
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, Autoplay]}
      slidesPerView={2}
      spaceBetween={256}
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      autoplay={{ delay: 3000 }}
      loop={true}
      style={{ paddingBottom: '20px' }}
    >
      <div className="h-8" />
      {AssetContext[CATEGORIES_CACHE_TYPE] ? (
        AssetContext[CATEGORIES_CACHE_TYPE].map((data, i) => {
          return (
            <SwiperSlide>
              <CategoryInfo
                FilterContext={FilterContext}
                navigate={navigate}
                key={i}
                {...data}
              />
            </SwiperSlide>
          )
        })
      ) : (
        <div>No Data</div>
      )}
    </Swiper>
  )
}

const CategoryDesktop = ({ AssetContext, FilterContext, navigate }) => {
  return (
    <>
      <div className="grid grid-cols-3 gap-x-12">
        {AssetContext[CATEGORIES_CACHE_TYPE] ? (
          AssetContext[CATEGORIES_CACHE_TYPE].filter((el, id) => id <= 2).map(
            (prop, key) => (
              <CategoryInfo
                FilterContext={FilterContext}
                navigate={navigate}
                key={key}
                {...prop}
              />
            ),
          )
        ) : (
          <div>No Data</div>
        )}
      </div>
      <div className="flex gap-x-12 w-full items-center justify-center">
        {AssetContext[CATEGORIES_CACHE_TYPE] ? (
          AssetContext[CATEGORIES_CACHE_TYPE].filter(
            (el, id) => id > 2 && id < 5,
          ).map((prop, key) => (
            <CategoryInfo
              FilterContext={FilterContext}
              navigate={navigate}
              key={key}
              {...prop}
            />
          ))
        ) : (
          <div>No Data</div>
        )}
      </div>
    </>
  )
}

const BrowseCategorySection = () => {
  const [show, setshow] = useState(false)
  const AssetContext = useContext(assetContext)
  const FilterContext = useContext(filterContext)
  const Responsive = useContext(ResponsiveContext)
  const navigate = useNavigate()
  const basePosition = 'translate-y-0 opacity-100'
  const picAnimation = 'translate-y-5 opacity-0'
  return (
    <div className="relative items-center md:px-60 px-4 md:mb-20 mb-8 md:pb-24 pb-4 w-full">
      <TitleSection
        title={'Cari berdasarkan kategori'}
        additionalStyle="md:mb-10 mb-8"
      />
      <InView
        onChange={(inView, entry) => {
          setshow(inView)
        }}
      >
        {Responsive ? (
          <CategoryMobile
            AssetContext={AssetContext}
            FilterContext={FilterContext}
            navigate={navigate}
          />
        ) : (
          <div
            className={`flex md:flex-col items-center justify-center w-full gap-y-12 transform ease-in-out duration-700 ${
              show ? basePosition : picAnimation
            }`}
          >
            <CategoryDesktop
              AssetContext={AssetContext}
              FilterContext={FilterContext}
              navigate={navigate}
            />
          </div>
        )}
      </InView>
    </div>
  )
}

export default BrowseCategorySection
