const VerifiedLogo = ({
  viewBox,
  size,
  onClick,
  divClass,
  color,
  ...props
}) => (
  <div onClick={onClick} className={divClass} {...props}>
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 8.60718C18 9.49908 16.3784 10.1153 16.1108 10.918C15.8432 11.7207 16.7838 13.2045 16.2811 13.8937C15.7784 14.5828 14.0919 14.145 13.3946 14.6558C12.6973 15.1666 12.5838 16.8937 11.773 17.1693C10.9622 17.445 9.89192 16.0909 9.00002 16.0909C8.10812 16.0909 7.02165 17.4369 6.21893 17.1774C5.41624 16.9179 5.29461 15.1666 4.59731 14.6639C3.90002 14.1612 2.22973 14.599 1.70271 13.9017C1.17569 13.2044 2.14055 11.7531 1.87298 10.926C1.60541 10.099 0 9.49904 0 8.60714C0 7.71524 1.62162 7.09904 1.88919 6.29631C2.15676 5.49359 1.21623 4.00983 1.70271 3.31253C2.18919 2.61524 3.89189 3.06117 4.58919 2.55036C5.28648 2.03954 5.4 0.312539 6.2108 0.0368402C7.02161 -0.238858 8.10808 1.12331 8.99998 1.12331C9.89188 1.12331 10.9783 -0.222647 11.7811 0.0368402C12.5838 0.296328 12.7054 2.04763 13.4027 2.55036C14.1 3.05304 15.7784 2.61524 16.2892 3.31253C16.8 4.00983 15.8513 5.46117 16.1189 6.28819C16.3865 7.11529 18 7.71528 18 8.60718Z"
        fill="#1189F7"
      />
      <path
        d="M7.92165 11.9881C7.70677 11.9881 7.50086 11.9038 7.34841 11.7505L5.18356 9.58566C4.86655 9.26865 4.86655 8.75619 5.18356 8.43917C5.50057 8.12216 6.01303 8.12216 6.33004 8.43917L7.86815 9.97648L11.4827 5.65406C11.7698 5.31191 12.2797 5.26404 12.6252 5.5527C12.969 5.83974 13.0135 6.35135 12.7273 6.69512L8.54356 11.6978C8.39763 11.8721 8.1852 11.9775 7.95733 11.9873C7.94599 11.9881 7.9338 11.9881 7.92165 11.9881Z"
        fill="white"
      />
    </svg>
  </div>
)
export default VerifiedLogo
