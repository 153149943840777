import { useState } from 'react'
import InView from 'react-intersection-observer'
import { walletCollectionImage } from '../../../../assets'
import ButtonComponent from '../../../../component/Button/ButtonComponent'
import TitleSection from '../TitleSection'

const FiatSection = ({ handleTo }) => {
  const [show, setshow] = useState(false)
  const basePosition = 'translate-y-0 opacity-100'
  const picAnimation = 'translate-y-5 opacity-0'
  return (
    <div className="flex flex-col justify-center items-center md:px-44 px-4 w-full bg-hijau_theme_soft md:py-28 py-8">
      <div className="md:w-screen_while_big md:px-44">
        <TitleSection
          title={'Mau beli aset tapi males pakai uang Kripto?'}
          info={
            'Repot beli NFT pakai kripto? NFTSaya menyediakan berbagai macam kanal pembayaran yang akan memudahkan anda di setiap pembelian aset'
          }
          additionalStyle="md:px-24"
        />
        <InView
          onChange={(inView, entry) => {
            setshow(inView)
          }}
        >
          <div
            className={`justify-center items-center w-full flex flex-col transform ease-in-out duration-700 ${
              show ? basePosition : picAnimation
            }`}
          >
            <img
              src={walletCollectionImage}
              className="md:mt-6 mt-6 mb-12"
              alt="wallet"
            />
            <ButtonComponent
              type="normal"
              color="primary"
              size="md"
              customWidth={48}
              handleClick={handleTo}
              text="Beli Sekarang"
            />
          </div>
        </InView>
      </div>
    </div>
  )
}

export default FiatSection
