const ButtonSquare = ({
  buttonStyle,
  buttonColor,
  textStyle,
  textColor,
  text,
  useHover,
  hoverColor,
  rounded,
  handleClick,
  ...props
}) => {
  return (
    <button
      type="button"
      className={`${buttonStyle} ${rounded} ${buttonColor} ${
        useHover &&
        `transform transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300`
      }`}
      onClick={handleClick}
      {...props}
    >
      <p className={`font-poppins font-semibold ${textStyle} ${textColor}`}>
        {text}
      </p>
    </button>
  )
}

ButtonSquare.defaultProps = {
  text: 'Belum Ada Teks',
  buttonStyle: 'py-4 px-6',
  buttonColor: 'bg-hijau_hutan',
  textColor: 'text-white',
  rounded: 'rounded-xl',
  useHover: true,
  hoverColor: 'bg-blue_theme',
}

export default ButtonSquare
