import { PatternAbstract } from '../../../../assets'
import ButtonSquare from '../../../../component/Button/ButtonSquare'

const PersuadeSection = ({ handleTo }) => {
  return (
    <div className="flex items-center bg-hijau_hutan md:py-0 py-8 md:px-0 px-4 w-full overflow-hidden">
      <div className="flex md:grid grid-cols-2 relative">
        <div className="flex h-full w-full absolute md:relative md:top-2 top-16 -left-8 z-0">
          <img src={PatternAbstract} alt="" className="md:h-full h-52 w-auto" />
        </div>
        <div className="flex flex-col md:px-8 px-0 justify-center z-10">
          <p className="font-poppins font-bold md:text-4xl text-2xl leading-normal tracking-wide text-white">
            Ayo tunggu apa lagi? Cari NFT favoritmu di NFT Saya
          </p>
          <div className="w-full mt-4">
            <ButtonSquare
              buttonColor={'bg-white'}
              buttonStyle="px-12 py-4"
              textColor={'text-hijau_hutan'}
              useHover={true}
              text={'Telusuri'}
              handleClick={handleTo}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PersuadeSection
