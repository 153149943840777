import { BgLanding, HumanForm } from '../../../../assets'
import InView from 'react-intersection-observer'
import { useState } from 'react'
import ButtonComponent from '../../../../component/Button/ButtonComponent'

const HeaderSection = ({ handleTo }) => {
  const [show, setshow] = useState(false)
  const basePosition = 'translate-x-0 opacity-100'
  const teksAnimation = '-translate-x-3 opacity-0'
  const picAnimation = 'translate-x-3 opacity-0'
  return (
    <InView
      onChange={(inView, entry) => {
        setshow(inView)
      }}
    >
      <div
        className={`justify-center items-center h-screen_while_big flex relative w-full 2xl:px-0 px-4 md:px-14 md:pt-0 pt-16`}
        id="container"
      >
        <div className="absolute right-0 md:opacity-100 opacity-5 z-0 -top-12">
          <img src={BgLanding} alt="" className="w-full h-full object-cover" />
        </div>
        <div className="grid justify-items-center md:grid-cols-2 grid-cols-1 md:gap-x-8 gap-x-6 lg:w-4/5 fhd:w-2/3 w-full">
          <div
            className={`flex flex-col md:order-1 order-2 justify-center md:items-start items-center h-full transform ease-in-out md:pl-8 duration-700 ${
              show ? basePosition : teksAnimation
            }`}
          >
            <p className="text-2xl md:text-5xl md:mt-0 mt-4 mb-4 font-poppins font-bold md:text-left text-center 2xl:leading-normal md:leading-normal tracking-wide text-teks">
              Jual beli aset digital terbaik di
              <span className="bg-gradient-to-r from-green_theme_500 to-blue_theme_500 text-transparent bg-clip-text">
                {' '}
                NFT Saya
              </span>
            </p>
            <p className="md:text-lg text-base font-poppins font-normal md:text-left text-center md:leading-normal md:mb-12 mb-6 text-teks_2">
              NFT Saya mempersembahkan NFT marketplace terbaik dan terlengkap di
              Indonesia dari pembuat atau pekerja seni terbaik yang ada di
              Indonesia maupun Mancanegara
            </p>
            <div className="flex flex-row md:px-0 px-2 h-14">
              <ButtonComponent
                color="primary"
                type="normal"
                handleClick={handleTo}
                text="Telusuri"
                customWidth={40}
              />
            </div>
          </div>
          <div
            className={`md:order-2 order-1 mb-4 md:mb-0 w-full flex justify-center items-center relative transform ease-in-out duration-700 z-20 ${
              show ? basePosition : picAnimation
            }`}
          >
            <div className="bg-white flex overflow-hidden z-10 rounded-lg p-4 shadow-cardLanding h-72 w-72 md:h-landing_image md:w-landing_image">
              <img
                src={HumanForm}
                className="object-cover w-full h-full rounded-lg"
                alt="human"
              />
            </div>
          </div>
        </div>
      </div>
    </InView>
  )
}

export default HeaderSection
