import { useState } from 'react'
import InView from 'react-intersection-observer'
import {
  BookImage,
  CollectionImage,
  CurlyLine,
  DotsPattern,
  NFTIllustration,
  WalletImage,
} from '../../../../assets'
import TitleSection from '../TitleSection'

const dataInfo = [
  {
    img: WalletImage,
    title: 'Buat Wallet',
    content:
      'Buka wallet Metamask, setelah itu tekan tombol Masuk untuk melakukan koleksi ke wallet kamu',
    isLeft: false,
  },
  {
    img: CollectionImage,
    title: 'Buat Koleksimu',
    content:
      'Tekan tombol Koleksi Saya di logo profil di kanan atas untuk membuat koleksi kamu',
    isLeft: true,
  },
  {
    img: NFTIllustration,
    title: 'Buat Aset Digitalmu',
    content:
      'Unggah aset yang kamu buat (gambar, video, musik) untuk dikonversi menjadi NFT',
    isLeft: false,
  },
  {
    img: BookImage,
    title: 'Jual Asetmu',
    content:
      'Tentukan harga dan metode penjualanmu (Harga pas, Bidding) kemudian tunggu asetmu terjual',
    isLeft: true,
  },
]

const ContentInfo = ({ img, title, content, idx, isLeft, ...prop }) => {
  const [show, setshow] = useState(false)
  const fromLeft = {
    basePosition: '-translate-x-full opacity-0',
    slidePosition: 'translate-x-0 opacity-100',
  }
  const fromRight = {
    basePosition: 'translate-x-full opacity-0',
    slidePosition: 'translate-x-0 opacity-100',
  }
  return (
    <InView
      onChange={(inView, entry) => {
        setshow(inView)
        // console.log('TES', inView, entry)
      }}
    >
      <div
        className="grid md:grid-cols-2 grid-cols-1 items-center w-full justify-center gap-x-36 md:mt-0 md:mb-0"
        {...prop}
      >
        <div className={`${isLeft && 'order-last'} md:visible invisible`}>
          <p
            className={`font-poppins font-semibold text-hijau_hutan ${
              isLeft ? 'text-left' : 'text-right'
            } text-2xl`}
          >
            Langkah {idx}
          </p>
        </div>
        <div
          className={`md:bg-white bg-none flex flex-col items-center md:px-10 px-4 md:pb-8 pb-4 md:pt-6 pt-4 md:shadow-cardLanding shadow-none rounded-2xl transform ease-in-out duration-700 ${
            isLeft
              ? show
                ? fromLeft.slidePosition
                : fromLeft.basePosition
              : show
              ? fromRight.slidePosition
              : fromRight.basePosition
          }`}
        >
          <img className="w-40 h-40 mb-2" src={img} alt="content" />
          <p className="font-poppins font-semibold md:text-xl text-lg md:mb-4 mb-2 text-teks">
            {title}
          </p>
          <p className="font-poppins font-normal md:text-base text-sm text-center text-teks_2 mx-8">
            {content}
          </p>
        </div>
      </div>
    </InView>
  )
}

const StepsLandingSection = () => (
  <div className="flex flex-col items-center md:px-36 px:20 md:mb-28 mb-4 md:mt-40 mt-36 w-full md:w-screen_while_big relative">
    <TitleSection
      title={'Bagaimana caranya? Mudah kok!'}
      info={
        'Kamu gak perlu bingung lagi, langkah untuk membuat dan menjual aset kami permudah'
      }
    />
    <div className="absolute right-8" style={{ top: '400px' }}>
      <img src={DotsPattern} alt="" className="w-full h-full" />
    </div>
    <div
      className="absolute left-16 md:visible invisible"
      style={{ top: '1400px' }}
    >
      <img src={DotsPattern} alt="" className="w-full h-full" />
    </div>
    <div className="absolute left-8" style={{ top: '800px' }}>
      <img src={CurlyLine} alt="" className="w-full h-full" />
    </div>
    <div
      className="absolute flex h-16 w-16 bg-hijau_theme_soft rounded-full right-28"
      style={{ top: '1180px' }}
    />
    {/* <p className="text-4xl mb-4 font-poppins font-semibold md:text-left text-center 2xl:leading-normal md:leading-normal tracking-normal text-teks">Bagaimana caranya? Mudah ko! </p>
            <p className="text-lg mb-4 font-poppins font-normal md:text-left text-center 2xl:leading-normal md:leading-normal tracking-normal text-teks_2">Kamu gak perlu bingung lagi, langkah untuk membuat dan menjual aset kami permudah </p> */}
    <div className="flex flex-col w-full relative items-center mt-10">
      <div className="md:flex hidden w-10 h-full absolute top-0 right-auto left-auto py-40">
        <div className="w-1/2 border-r-2 border-dashed border-hijau_hutan relative">
          <div className="grid grid-flow-row gap-y-80 absolute -top-2 -right-3.5">
            <div className="h-6 w-6 rounded-full bg-oranye">
              <div className="animate-ping h-6 w-6 rounded-full bg-oranye" />
            </div>
            <div className=" h-6 w-6 rounded-full bg-oranye">
              <div className="animate-ping h-6 w-6 rounded-full bg-oranye" />
            </div>
            <div className=" h-6 w-6 rounded-full bg-oranye">
              <div className="animate-ping h-6 w-6 rounded-full bg-oranye" />
            </div>
            <div className="h-6 w-6 rounded-full bg-oranye">
              <div className="animate-ping h-6 w-6 rounded-full bg-oranye" />
            </div>
          </div>
        </div>
      </div>
      {/* {dataInfo?.map((prop, i) => <ContentInfo key={i} idx={i+1} {...prop} />)} */}
      {dataInfo.map(({ img, title, content, isLeft }, idx) => (
        <ContentInfo
          img={img}
          key={idx}
          title={title}
          content={content}
          isLeft={isLeft}
          idx={idx + 1}
        />
      ))}
    </div>
  </div>
)

export default StepsLandingSection
