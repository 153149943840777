import { useContext } from 'react'
import {
  CoinFiatRoundLogo,
  HandCoinRoundLogo,
  StarCoinRoundLogo,
  StaticAsetLanding,
  TagRoundLogo,
} from '../../../../assets'
import CardTestimoni from '../../../../component/Card/CardTestimoni'
import env from '../../../../config/env'
import ResponsiveContext from '../../../../context/Responsive/responsiveContext'

const BenefitSection = () => {
  const responsive = useContext(ResponsiveContext)
  const testimoniData = [
    {
      img: HandCoinRoundLogo,
      title: 'Biaya admin yang rendah',
      content: `Dari setiap transaksi penjualan yang dilakukan, kami hanya mengambil biaya admin sebesar ${env.APPS_ADMIN_FEE}%`,
    },
    {
      img: CoinFiatRoundLogo,
      title: 'Melakukan transaksi dengan Rupiah',
      content:
        'Kami menyediakan metode pembayaran menggunakan Rupiah agar kamu yang tidak memiliki Kripto tetap dapat bertransaksi',
    },
    {
      img: TagRoundLogo,
      title: 'Gas fee yang kecil',
      content:
        'Setiap transaksi yang dilakukan pada jaringan blockchain akan dikenakan biaya Gas. Dengan kami biaya tersebut sangat terjangkau',
    },
    {
      img: StarCoinRoundLogo,
      title: 'Royalti',
      content:
        'Kamu akan mendapatkan royalti dari setiap transaksi penjualan dari aset yang kamu buat',
    },
  ]
  return (
    <div className="grid md:grid-cols-2 grid-cols-1 items-center px-0 md:mb-32 mb-6 w-full 2xl:w-screen_while_big">
      <div className="bg-hijau_hutan flex h-full w-full relative">
        <p className="font-poppins font-bold md:text-5xl text-3xl leading-normal tracking-wide mx-12 md:mt-14 mt-10 md:mb-0 mb-8 text-white">
          Keuntungan lainnya di NFT Saya
        </p>
        {!responsive && (
          <img
            src={StaticAsetLanding}
            alt=""
            className="absolute -bottom-0 h-auto"
            style={{ width: '500px' }}
          />
        )}
      </div>
      <div
        className={`flex md:flex-col flex-row px-8 gap-x-8 bg-hijau_hutan md:bg-white ${
          responsive && 'overflow-x-scroll w-full'
        }`}
      >
        <div className="flex md:flex-col md:pb-0 pb-8">
          {testimoniData.map(({ img, title, content }, idx) => (
            <CardTestimoni
              key={idx}
              img={img}
              title={title}
              content={content}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default BenefitSection
