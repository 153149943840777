import React from 'react'
import BinanceIcon from '../../assets/icon/binance_icon'
import EthereumIcon from '../../assets/icon/ethereum_icon'
import MaticIcon from '../../assets/icon/matic_icon'
import {
  BLOCKCHAIN_BINANCE,
  BLOCKCHAIN_ETHERIUM,
} from '../../utils/constants/blockChainTypes'

const BlockchainIcon = ({ blockchainType, size }) => {
  if (blockchainType === BLOCKCHAIN_BINANCE) {
    return <BinanceIcon size={size} />
  } else if (blockchainType === BLOCKCHAIN_ETHERIUM) {
    return <EthereumIcon size={size} />
  } else {
    return <MaticIcon size={size} />
  }
}

const equalityProps = (prev, next) => {
  return prev.blockchainType === next.blockchainType && prev.size === next.size
}

BlockchainIcon.defaultProps = {
  blockchainType: 1,
  size: 6,
}

export default React.memo(BlockchainIcon, equalityProps)
