import React, { useState } from 'react'
import { ListingMetaImg } from '../../../../assets'
import InView from 'react-intersection-observer'

const ListingMetaSection = () => {
  const [show, setshow] = useState(false)
  const basePosition = 'translate-y-0 opacity-100'
  const picAnimation = 'translate-y-5 opacity-0'
  return (
    <div className="w-full flex bg-gradient-to-b from-hijau_theme_soft to-white items-center justify-center md:py-8 py-6 md:mb-12 mb-2">
      <InView
        onChange={(inView, entry) => {
          setshow(inView)
        }}
      >
        <div
          className={`grid md:grid-cols-2 grid-cols-1 fhd:mx-89px mx-4 lg:w-screen_while_big w-80 gap-x-2 transform ease-in-out duration-700 ${
            show ? basePosition : picAnimation
          }`}
        >
          <div className="w-full h-full order-2 md:order-1 flex items-end justify-center">
            <img
              src={ListingMetaImg}
              alt=""
              className="md:w-96 md:h-96 w-56 h-56"
            />
          </div>
          <div className="h-full flex order-1 md:order-2 flex-col items-start justify-center ">
            <p className="font-poppins md:text-left text-center leading-normal tracking-wide font-bold md:text-4xl text-xl text-teks mb-4">
              Pernah minting di marketplace lain?{' '}
              <span className="text-hijau_hutan">Gak masalah!</span>
            </p>
            <p className="font-poppins md:text-left text-center font-normal md:text-base text-sm text-teks_2">
              Minting di blockchain dan marketplace manapun, semua bisa dijual
              dengan mudah di NFT Saya
            </p>
          </div>
        </div>
      </InView>
    </div>
  )
}

export default ListingMetaSection
