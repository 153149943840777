import { useContext, useEffect, lazy, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import assetContext from '../../context/Asset/assetContext';
import {
  CATEGORIES_CACHE_TYPE,
  RANDOM_ASSETS_CACHE_TYPE
} from '../../utils/constants/assetCacheTypes';
import HeaderSection from './component/Section/HeaderSection';
import UtilitySection from './component/Section/UtilitySection';
import BarterSection from './component/Section/BarterSection';
import BestOfferingSection from './component/Section/BestOfferingSection';
import BrowseCategorySection from './component/Section/CategorySection';
import PersuadeSection from './component/Section/PersuadeSection';
import HeaderMobileContext from '../../context/HeaderMobile/HeaderMobileContext';
import StepsLandingSection from './component/Section/StepsLandingSection';
import FiatSection from './component/Section/FiatSection';
import BenefitSection from './component/Section/BenefitSection';
import ListingMetaSection from './component/Section/ListingMetaSection';
const Footer = lazy(() => import('../../container/Layout/Footer'));

const LandingPage = () => {
  const { getCache } = useContext(assetContext);
  const navigate = useNavigate();
  const LandingContext = useContext(HeaderMobileContext);
  const { setBgLanding } = LandingContext;
  useEffect(() => {
    getCache([CATEGORIES_CACHE_TYPE, RANDOM_ASSETS_CACHE_TYPE]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToListAset = useCallback(() => {
    navigate('/assets');
  }, [navigate]);

  const handleToUtility = useCallback(() => {
    navigate('/list-project-utilites');
  }, [navigate]);

  window.onscroll = async () => {
    if (document.documentElement.scrollTop >= 1 && document.documentElement.scrollTop <= 50) {
      setBgLanding(false);
    }
    if (document.documentElement.scrollTop === 0) {
      setBgLanding(true);
    }
  };

  useEffect(() => {
    setBgLanding(true);
  }, [setBgLanding]);

  return (
    <div className="overflow-x-hidden">
      <div className="2xl:mx-auto justify-center flex flex-col items-center">
        <HeaderSection handleTo={handleToListAset} />
        <StepsLandingSection />
        <FiatSection handleTo={handleToListAset} />
        <UtilitySection handleToUtility={handleToUtility} />
        <ListingMetaSection />
        <BarterSection />
        <BenefitSection />
        <BestOfferingSection />
        <BrowseCategorySection />
        <PersuadeSection handleTo={handleToListAset} />
      </div>
      <Footer />
    </div>
  );
};

export default LandingPage;
